import { createSlice } from "@reduxjs/toolkit";
import { EditProfileThunk, ProfileThunk } from "../Thunk/EditProfileThunk";

let initState = {
	reload: false,
	profile: null,
};

const EditProfileReducer = createSlice({
	name: "EditProfile",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(ProfileThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(ProfileThunk.fulfilled, (state, action) => {
				state.reload = false;

				state.profile = action.payload.data;
				localStorage.setItem("avatar", action.payload.data.avatar);
				localStorage.setItem("name", action.payload.data.name);
				localStorage.setItem("email", action.payload.data.email);
				localStorage.setItem("phone", action.payload.data.phone);
			})
			.addCase(ProfileThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.error.message;
			})
			.addCase(EditProfileThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(EditProfileThunk.fulfilled, (state, action) => {
				state.reload = false;
			})
			.addCase(EditProfileThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.error.message;
			});
	},
});
export default EditProfileReducer.reducer;
