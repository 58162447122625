import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const ProfileThunk = createAsyncThunk(
	"ProfileThunk",
	async (_, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;
		const token = localStorage.getItem("nueawin_token");

		try {
			const res = await axios.get(
				"https://api.nueawin.com/auth/profile",

				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return res?.data;
		} catch (error) {
			if (error.response) {
				return rejectWithValue(error.response.data);
			} else {
				return rejectWithValue({ message: "An unexpected error occurred" });
			}
		}
	}
);

export const EditProfileThunk = createAsyncThunk(
	"EditProfileThunk",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;
		const token = localStorage.getItem("nueawin_token");

		try {
			const res = await axios.post(
				"https://api.nueawin.com/auth/profile",
				arg,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return res?.data;
		} catch (error) {
			if (error.response) {
				return rejectWithValue(error.response.data);
			} else {
				return rejectWithValue({ message: "An unexpected error occurred" });
			}
		}
	}
);
