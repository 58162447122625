import React from "react";
import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from "@mui/material";
import { Error } from "@mui/icons-material";
import { IoIosArrowDown } from "react-icons/io";

const textFieldStyle = {
	"& .MuiOutlinedInput-notchedOutline": {
		textAlign: "right",
	},
	"& .MuiInputLabel-root": {
		left: "auto",
		right: "29px",
		transformOrigin: "top right",
	},

	"& .MuiOutlinedInput-root": {
		"@media (max-width: 600px)": {
			height: "46px",
		},
	},
};

const FacilitiesForm = ({
	errors,
	companionGender,
	companionName,
	companionAge,
	handleInputChange,
	companionNationality,
	ageOfBeneficiaryArray,
	handleCompanionGenderChange,
}) => {
	return (
		<Box className=' border-[1px] border-solid border-[#ddd] rounded-md py-5 px-6'>
			<TextField
				type='text'
				fullWidth
				margin='normal'
				sx={textFieldStyle}
				name='companionName'
				value={companionName}
				onChange={handleInputChange}
				label=' اسم المرافق بالكامل '
			/>

			{errors?.name && (
				<Box className='flex justify-start items-center'>
					<Error sx={{ color: "red", fontSize: "18px" }} />
					<p className=' text-red-500'>{errors?.name}</p>
				</Box>
			)}

			<FormControl
				fullWidth
				margin='normal'
				sx={{
					"& .MuiOutlinedInput-notchedOutline": {
						textAlign: "right",
					},
					"& .MuiInputLabel-root": {
						left: "auto",
						right: "29px",
						transformOrigin: "top right",
					},
				}}>
				<InputLabel id='city-label'>عُمْر المرافق</InputLabel>
				<Select
					sx={{
						"& .MuiSelect-icon": {
							right: "auto",
							left: 8,
						},
					}}
					labelId='city-label'
					id='city-select'
					value={companionAge}
					label='اختر عُمْر المرافق'
					onChange={handleInputChange}
					IconComponent={IoIosArrowDown}
					name='companionAge'>
					{ageOfBeneficiaryArray?.map((item) => (
						<MenuItem value={item?.title} key={item?.id}>
							{item?.title}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			{errors?.companionAge && (
				<Box className='flex justify-start items-center'>
					<Error sx={{ color: "red", fontSize: "18px" }} />
					<p className=' text-red-500'>{errors?.companionAge}</p>
				</Box>
			)}
			<TextField
				sx={textFieldStyle}
				label='الجنسية'
				type='text'
				name='companionNationality'
				value={companionNationality}
				onChange={handleInputChange}
				fullWidth
				margin='normal'
			/>

			{errors?.companionNationality && (
				<Box className='flex justify-start items-center'>
					<Error sx={{ color: "red", fontSize: "18px" }} />
					<p className=' text-red-500'>{errors?.companionNationality}</p>
				</Box>
			)}

			<Box className='mt-4'>
				<FormControl>
					<FormLabel id='demo-radio-buttons-group-label'>جنس المرافق</FormLabel>
					<RadioGroup
						sx={{
							"&.MuiFormGroup-root": {
								display: "flex",
								flexDirection: "row",
							},
						}}
						defaultValue='male'
						value={companionGender}
						onChange={handleCompanionGenderChange}
						name='radio-buttons-group'>
						<FormControlLabel
							sx={{
								"&.MuiFormControlLabel-root": {
									marginRight: "-10px",
								},
							}}
							value='male'
							control={<Radio />}
							label='ذكر'
						/>

						<FormControlLabel value='female' control={<Radio />} label='أنثي' />
					</RadioGroup>
				</FormControl>
				{errors?.companionGender && (
					<Box className='flex justify-start items-center'>
						<Error sx={{ color: "red", fontSize: "18px" }} />
						<p className=' text-red-500'>{errors?.companionGender}</p>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default FacilitiesForm;
