import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const RegisterThunk = createAsyncThunk(
	"RegisterThunk",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;
		try {
			const res = await axios.post(
				"https://api.nueawin.com/auth/register/client",
				arg
			);

			return res?.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
