import { createSlice } from "@reduxjs/toolkit";
import { LoginThunk } from "../Thunk/LoginThunk";

let initState = {
	reload: false,
	openAuthModal: false,
	openRequestCarModal: false,
};

const CreateOrderReducer = createSlice({
	name: "CreateOrder",

	initialState: initState,
	reducers: {
		handleOpenRequestCarModal: (state) => {
			state.openRequestCarModal = true;
		},
		handleCloseRequestCarModal: (state) => {
			state.openRequestCarModal = false;
		},
		handleOpenAuthModal: (state) => {
			state.openAuthModal = true;
		},
		handleCloseAuthModal: (state) => {
			state.openAuthModal = false;
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(LoginThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(LoginThunk.fulfilled, (state, action) => {
				state.reload = false;
			})
			.addCase(LoginThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.error.message;
			});
	},
});
export const {
	handleOpenRequestCarModal,
	handleCloseRequestCarModal,
	handleOpenAuthModal,
	handleCloseAuthModal,
} = CreateOrderReducer.actions;
export default CreateOrderReducer.reducer;
