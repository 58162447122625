import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";

import { IoCloseSharp } from "react-icons/io5";
import { AccountCircleIcon } from "../../Asset/Icons";

import "./AuthModal.css";

// tabs
import RegisterTab from "./RegisterTab";
import LoginTab from "./LoginTab";
import { useDispatch, useSelector } from "react-redux";
import { handleCloseAuthModal } from "../../RTK/Reducers/CreateOrderReducer";

function AuthModal() {
	// handle open or close Auth modal  and  Request car modal
	const { openAuthModal } = useSelector((state) => state.CreateOrderReducer);

	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(0);
	const handleChangeTab = (event, newValue) => {
		setActiveTab(newValue);
	};

	return (
		<Modal
			open={openAuthModal}
			onClose={() => dispatch(handleCloseAuthModal())}
			className=' overflow-auto'>
			<Box className='bg-white p-4 mx-auto mt-16 w-[95%] md:w-1/2 rounded-lg '>
				{/* Close Icon */}
				<IconButton
					onClick={() => {
						dispatch(handleCloseAuthModal());
					}}
					className='absolute top-2 right-2'>
					<IoCloseSharp />
				</IconButton>

				{/* User Icon */}
				<div className='flex justify-center mt-2 '>
					<AccountCircleIcon className=' text-color_03 w-24 user-icon' />
				</div>
				<Tabs value={activeTab} onChange={handleChangeTab} centered>
					<Tab label='تسجيل الدخول' />
					<Tab label='إنشاء حساب' />
				</Tabs>
				{activeTab === 0 && <LoginTab />}
				{activeTab === 1 && <RegisterTab setActiveTab={setActiveTab} />}
			</Box>
		</Modal>
	);
}

export default AuthModal;
