import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import { FaCartArrowDown } from "react-icons/fa";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { MdAdminPanelSettings } from "react-icons/md";

export default function UserAccountMenu({
	setAnchorEl,
	anchorEl,
	open,
	mobileMenu,
}) {
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogOut = () => {
		localStorage.clear();
		handleClose();
	};
	const name = localStorage.getItem("name");
	const avatar = localStorage.getItem("avatar");

	const navigate = useNavigate();
	return (
		<Menu
			anchorEl={anchorEl}
			id='account-menu'
			open={open}
			onClose={handleClose}
			onClick={handleClose}
			PaperProps={{
				elevation: 0,
				sx: {
					width: mobileMenu ? "100%" : "auto",
					overflow: "visible",
					filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
					mt: 1.5,
					"& .MuiAvatar-root": {
						width: 30,
						height: 30,
						ml: 1,
						mr: -1,
					},
					"&::before": {
						content: '""',
						display: "block",
						position: "absolute",
						top: 0,
						right: mobileMenu ? "auto" : 14,
						left: mobileMenu ? 50 : "auto",
						width: 10,
						height: 10,
						bgcolor: "background.paper",
						transform: "translateY(-50%) rotate(45deg)",
						zIndex: 0,
					},
				},
			}}
			transformOrigin={{ horizontal: "right", vertical: "top" }}
			anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
			<MenuItem className=' !cursor-default'>
				<Avatar src={avatar} /> <div className='w-full truncate'>{name}</div>
			</MenuItem>
			<Divider />

			<MenuItem
				onClick={() => {
					handleClose();
					navigate("/user-dashboard");
				}}
				className=' !font-medium'>
				<ListItemIcon
					sx={{
						"&.MuiListItemIcon-root": {
							color: "initial",
							minWidth: "30px",
						},
					}}>
					<Settings fontSize='small' />
				</ListItemIcon>
				إعدادت الحساب
			</MenuItem>

			<MenuItem
				onClick={() => {
					handleClose();
					navigate("/user-dashboard/change-password");
				}}
				className=' !font-medium'>
				<ListItemIcon
					sx={{
						"&.MuiListItemIcon-root": {
							color: "initial",
							minWidth: "30px",
						},
					}}>
					<MdAdminPanelSettings fontSize='large' />
				</ListItemIcon>
				تغيير كلمة المرور
			</MenuItem>

			<MenuItem
				onClick={() => {
					handleClose();
					navigate("/user-dashboard/my-orders");
				}}
				className=' !font-medium'>
				<ListItemIcon
					sx={{
						"&.MuiListItemIcon-root": {
							color: "initial",
							minWidth: "30px",
						},
					}}>
					<FaCartArrowDown fontSize='large' />
				</ListItemIcon>
				طلباتي
			</MenuItem>

			<MenuItem onClick={handleLogOut} className=' !font-medium'>
				<ListItemIcon
					sx={{
						"&.MuiListItemIcon-root": {
							color: "initial",
							minWidth: "30px",
						},
					}}>
					<Logout fontSize='small' />
				</ListItemIcon>
				تسجيل الخروج
			</MenuItem>
		</Menu>
	);
}
