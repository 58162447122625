import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useDispatch, useSelector } from "react-redux";
import { LoginThunk } from "../../RTK/Thunk/LoginThunk";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { Error } from "@mui/icons-material";
import { handleCloseAuthModal } from "../../RTK/Reducers/CreateOrderReducer";

const textFieldStyle = {
	"& .MuiOutlinedInput-notchedOutline": {
		textAlign: "right",
	},
	"& .MuiInputLabel-root": {
		left: "auto",
		right: "29px",
		transformOrigin: "top right",
	},

	"& .MuiOutlinedInput-root": {
		"@media (max-width: 600px)": {
			height: "46px",
		},
	},
};

const LoginTab = () => {
	const dispatch = useDispatch();

	const { reload } = useSelector((state) => state.LoginReducer);
	const [showPassword, setShowPassword] = useState(false);
	const [loginFormData, setLoginFormData] = useState({
		email: "",
		password: "",
	});

	const [errors, setErrors] = useState({
		mainError: "",
		email: "",
		password: "",
	});

	const resetError = () => {
		setErrors({
			mainError: "",
			email: "",
			password: "",
		});
	};

	const resetData = () => {
		setLoginFormData({
			email: "",
			password: "",
		});
	};

	const handleInputChange = (e) => {
		setLoginFormData({
			...loginFormData,
			[e.target.name]: e.target.value,
		});
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleLogin = async () => {
		resetError();
		dispatch(LoginThunk(loginFormData))
			.unwrap()
			.then((data) => {
				if (data?.code === 200 && data?.type === "success") {
					toast.success(data.message, { theme: "light" });
					resetData();
					dispatch(handleCloseAuthModal());
					window.location.reload();
				}
			})
			.catch((error) => {
				setErrors({
					...errors,
					mainError: error?.data?.message,
					email: error?.data?.email,
					password: error?.data?.password,
				});

				toast.error(error.message, { theme: "light" });
			});
	};

	return (
		<Box p={3}>
			<TextField
				sx={textFieldStyle}
				label='البريد الإلكتروني'
				type='email'
				name='email'
				value={loginFormData.email}
				onChange={handleInputChange}
				fullWidth
				margin='normal'
			/>
			{errors?.email && (
				<Box className='flex justify-start items-center'>
					<Error sx={{ color: "red", fontSize: "18px" }} />
					<p className=' text-red-500'>{errors?.email}</p>
				</Box>
			)}
			<TextField
				sx={textFieldStyle}
				label='كلمة المرور'
				type={showPassword ? "text" : "password"}
				name='password'
				value={loginFormData.password}
				onChange={handleInputChange}
				fullWidth
				margin='normal'
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<IconButton onClick={handleClickShowPassword}>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			{errors?.mainError && (
				<Box className='flex justify-start items-center'>
					<Error sx={{ color: "red", fontSize: "18px" }} />
					<p className=' text-red-500'>{errors?.mainError}</p>
				</Box>
			)}
			{errors?.password && (
				<Box className='flex justify-start items-center'>
					<Error sx={{ color: "red", fontSize: "18px" }} />
					<p className=' text-red-500'>{errors?.password}</p>
				</Box>
			)}
			<button
				onClick={handleLogin}
				disabled={reload || !loginFormData?.email || !loginFormData?.password}
				className='mt-4 w-full bg-color_01 hover:bg-color_03 text-white rounded-md h-12 tex-lg font-medium'>
				{reload ? <CircularProgress sx={{ color: "#fff" }} /> : "تسجيل الدخول"}
			</button>
		</Box>
	);
};

export default LoginTab;
