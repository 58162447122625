import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import InputBase from "@mui/material/InputBase";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import whiteLogo from "../../Asset/Imgs/noorwhite.png";
import { NavLink } from "react-router-dom";

import AccordionHeader from "../../Common/AccordionHeader/AccordionHeader";

// Icons
import { FaHome, FaTiktok } from "react-icons/fa";
import { RiSnapchatFill } from "react-icons/ri";
import { PiInstagramLogo } from "react-icons/pi";
import { AiOutlineClose } from "react-icons/ai";
import { GiCarKey } from "react-icons/gi";
import { FaFacebookF, FaXTwitter, FaRegUser } from "react-icons/fa6";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { ContactInfoThunk } from "../../RTK/Thunk/ContactInfoThunk";
import { SocialLinksThunk } from "../../RTK/Thunk/SocialLinksThunk";
import { SiteLogoThunk } from "../../RTK/Thunk/SiteLogoThunk";
import { FiExternalLink } from "react-icons/fi";

import { SectionsOfPagesThunk } from "../../RTK/Thunk/SectionsOfPagesThunk";
import { setCurrentPageId } from "../../RTK/Reducers/PagesReducer";
import { Newspaper } from "@mui/icons-material";
import { MdConnectWithoutContact } from "react-icons/md";
import { LastNewsThunk } from "../../RTK/Thunk/LastNewsThunk";
import UserAccountMenu from "./UserAccountMenu";
import AuthModal from "../AuthModal/AuthModal";
import RequestCarModal from "../RequestCarModal/RequestCarModal";
import {
	handleOpenAuthModal,
	handleOpenRequestCarModal,
} from "../../RTK/Reducers/CreateOrderReducer";

const Search = styled("div")(({ theme }) => ({
	position: "relative",
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	"&:hover": {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},

	marginLeft: 0,
	width: "100%",

	[theme.breakpoints.up("lg")]: {
		width: "100%",
		maxWidth: "300px",
	},
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: "100%",
	position: "absolute",
	pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	color: "#283078",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: "#636161",
	fontSize: "18px",
	width: "100%",
	// paddingLeft: "20px",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 0),
		borderRadius: "4px",
		paddingRight: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
		backgroundColor: "#ebf1f7",
	},
}));
const drawerWidth = "360px";

function AppBarBox(props) {
	const token = localStorage.getItem("nueawin_token");

	const dispatch = useDispatch();
	const { contactInfo } = useSelector((state) => state.ContactInfoReducer);
	const { socialLinks } = useSelector((state) => state.SocialLinksReducer);
	const { siteLogo } = useSelector((state) => state.SiteLogoReducer);
	const { pagesData } = useSelector((state) => state.PagesReducer);
	const { sliders } = useSelector((state) => state.LastNewsReducer);

	// handle user account menu...

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMenu, setMobileMenu] = React.useState(false);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const getDataRef = React.useRef(true);
	React.useEffect(() => {
		if (getDataRef.current) {
			getDataRef.current = false;
			dispatch(ContactInfoThunk());
			dispatch(SocialLinksThunk());
			dispatch(SiteLogoThunk());

			dispatch(LastNewsThunk());
		}
	}, [getDataRef]);

	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState);
	};

	/** social links  */
	const socialLinksItems = socialLinks?.map((item, index) => (
		<li key={item}>
			<a
				className='rounded-full border-[1px] group flex justify-center items-center !w-[34px] !h-[34px] p-1 group   border-color_03'
				href={item}>
				{item?.includes("facebook") ? (
					<FaFacebookF className=' group-hover:!text-[23px] !w-[50px]	 transition-[0.5s]   text-color_03 !text-[20px]' />
				) : item?.includes("twitter") ? (
					<FaXTwitter className=' group-hover:!text-[23px] !w-[50px]	 transition-[0.5s]  text-color_03 !text-[20px]' />
				) : item?.includes("snapchat") ? (
					<RiSnapchatFill className=' group-hover:!text-[23px] !w-[50px]	 transition-[0.5s]   text-color_03  !text-[20px]' />
				) : item?.includes("tiktok") ? (
					<FaTiktok className=' group-hover:!text-[23px] !w-[50px]	 transition-[0.5s] text-color_03 !text-[20px]' />
				) : item?.includes("instagram") ? (
					<PiInstagramLogo className=' group-hover:!text-[23px] !w-[50px]	 transition-[0.5s] text-color_03 !text-[20px]' />
				) : (
					<FiExternalLink className=' group-hover:!text-[23px] !w-[50px]	 transition-[0.5s] text-color_03 !text-[20px]' />
				)}
			</a>
		</li>
	));

	const MobileSocialLinksItems = socialLinks?.map((item, index) => (
		<li key={item}>
			<a
				className=' rounded-full border-[1px] group flex justify-center items-center !w-[34px] !h-[34px] p-1 group hover:border-color_03  border-color_03'
				href={item}>
				{item?.includes("facebook") ? (
					<FaFacebookF className='group-hover:!text-[23px] !w-[50px]	 transition-[0.5s]  group-hover:text-color_02 text-color_03 !text-[20px]' />
				) : item?.includes("twitter") ? (
					<FaXTwitter className='group-hover:!text-[23px] !w-[50px]	 transition-[0.5s]  group-hover:text-color_02 text-color_03 !text-[20px]' />
				) : item?.includes("snapchat") ? (
					<RiSnapchatFill className='group-hover:!text-[23px] !w-[50px]	 transition-[0.5s]  group-hover:text-color_02 text-color_03 !text-[20px]' />
				) : item?.includes("tiktok") ? (
					<FaTiktok className='group-hover:!text-[23px] !w-[50px]	 transition-[0.5s]  group-hover:text-color_02 text-color_03 !text-[20px]' />
				) : item?.includes("instagram") ? (
					<PiInstagramLogo className='group-hover:!text-[23px] !w-[50px]	 transition-[0.5s]  group-hover:text-color_02 text-color_03 !text-[20px]' />
				) : (
					<FiExternalLink className='group-hover:!text-[23px] !w-[50px]	 transition-[0.5s]  group-hover:text-color_02 text-color_03 !text-[20px]' />
				)}
			</a>
		</li>
	));

	const handleModalToggle = () => {
		if (token) {
			dispatch(handleOpenRequestCarModal());
		} else {
			dispatch(handleOpenAuthModal());
		}
		handleDrawerToggle();
	};
	const drawer = (
		<Box
			// onClick={handleDrawerToggle}
			sx={{ textAlign: "center" }}
			className='h-full bg-color_02 relative'>
			<div className=' flex justify-between  p-[20px] items-center gap-[20px]'>
				<div onClick={handleDrawerToggle} className=''>
					<AiOutlineClose
						className={`!text-[40px] !font-[600] text-color_06 cursor-pointer`}
					/>
				</div>
				<div className='box-img h-[80px]'>
					<img
						src={whiteLogo}
						alt=''
						className=' w-full h-full object-contain '
					/>
				</div>
			</div>

			<Divider />
			<div className='px-[26px] mt-[40px]'>
				<NavLink
					to={`/`}
					onClick={() => {
						handleDrawerToggle();
					}}
					className='border-b-[1px] gap-[10px] border-b-color_11 flex justify-start items-center w-full transition-[0.3s]  !text-color_06  !text-[20px] !font-medium text-center py-4'>
					<span className='w-6'>
						<FaHome />
					</span>
					الرئسية
				</NavLink>
				{pagesData?.map((item) => {
					if (item?.status && item?.has_sections) {
						return (
							<div key={item?.id}>
								<NavLink
									to={`/PagesCreator/${item?.id}/${item?.name}`}
									onClick={() => {
										handleDrawerToggle();
										dispatch(SectionsOfPagesThunk(item?.id));
										dispatch(setCurrentPageId(item));
									}}
									className='border-b-[1px] gap-[10px] border-b-color_11 flex justify-start items-center w-full transition-[0.3s]  !text-color_06  !text-[20px] !font-medium text-center py-4'>
									<span className='w-6'>
										<img src={item?.icon} alt='' />
									</span>
									{item?.name}
								</NavLink>
							</div>
						);
					}
				})}
			</div>
			<div className='flex !bg-color_02 justify-start items-start w-full flex-col gap-[16px] px-[26px]'>
				{pagesData?.map((page) => (
					<AccordionHeader
						key={page?.id}
						title={page?.name}
						icon={page?.icon}
						handleDrawerToggle={handleDrawerToggle}
						navItemsLink={page?.subPages}
					/>
				))}
				{sliders?.length !== 0 && (
					<NavLink
						to='/news'
						onClick={() => {
							handleDrawerToggle();
						}}
						className='border-b-[1px] gap-[10px] border-b-color_11 flex justify-start items-center w-full transition-[0.3s]  !text-color_06  !text-[20px] !font-medium text-center py-4'>
						<span className='w-6'>
							<Newspaper />
						</span>
						الأخبار
					</NavLink>
				)}

				<NavLink
					to='/contact-us'
					onClick={() => {
						handleDrawerToggle();
					}}
					className='border-b-[1px] mb-4 gap-[10px] border-b-color_11 flex justify-start items-center w-full transition-[0.3s]  !text-color_06  !text-[20px] !font-medium text-center py-4'>
					<span className='w-6'>
						<MdConnectWithoutContact />
					</span>
					تواصل معنا
				</NavLink>
			</div>
			<button
				onClick={handleModalToggle}
				className='text-md w-[90%] bg-color_03 px-4 h-9 rounded-md font-medium text-white hover:bg-color_10 hover:text-color_02 transition-all'>
				طلب سيارة
				<GiCarKey className='text-lg w-6' />
			</button>
			<div className='flex !bg-color_02 justify-start items-start w-full flex-col gap-[16px] px-[26px]'>
				<ul className='link_footer w-full flex justify-center items-center gap-[10px] my-14'>
					{socialLinksItems}
				</ul>
			</div>
		</Box>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;

	return (
		<>
			<div className=' bg-color_06 shadow-xl shadow-[#f3f3f3]'>
				{/* left-0 top-0 right-0 w-full h-fit */}
				<div className='md:py-3 md:p-0 p-3 container'>
					<CssBaseline />
					<AppBar
						component='nav'
						className='!relative !bg-transparent lg:flex !shadow-none !outline-none'>
						<Toolbar className=' !px-0 flex w-full justify-between items-center gap-[30px]'>
							<div className='flex  w-full justify-center gap-[12px] items-center flex-col'>
								<div className='flex w-full justify-evenly gap-[10px] items-center  '>
									<div className='flex justify-start gap-[12px] items-center  '>
										<Typography variant='h6' component='div'>
											<div className='box-img h-[80px]'>
												<img
													src={siteLogo}
													alt=''
													className=' w-full h-full object-contain'
												/>
											</div>
										</Typography>
										<div className=' hidden lg:block w-[1px] h-full min-h-[50px] bg-color_03 rounded-lg'></div>

										<div className='  hidden lg:flex  group justify-center items-center flex-col'>
											<h3 className=' text-color_02 text-[15px] font-medium '>
												خدمة العملاء
											</h3>
											<h6 className=' text-color_03 text-lg font-bold'>
												<span>{contactInfo?.phone}</span>
											</h6>
										</div>
									</div>

									<Search>
										<SearchIconWrapper>
											<SearchIcon className=' z-[1]' />
										</SearchIconWrapper>
										<StyledInputBase
											placeholder='البحث...'
											inputProps={{ "aria-label": "search" }}
										/>
									</Search>
									{console.log(token)}
									<Box
										sx={{ display: { xs: "none", lg: "flex" } }}
										className='justify-center items-center gap-4'>
										<div className='flex justify-center items-center gap-4 '>
											<button
												onClick={handleModalToggle}
												className='text-md bg-color_03 px-4 h-9 rounded-md font-medium text-white hover:bg-color_10 hover:text-color_02 transition-all '>
												طلب سيارة
												<GiCarKey className=' text-lg w-6 ' />
											</button>

											<UserAccountMenu
												mobileMenu={mobileMenu}
												setAnchorEl={setAnchorEl}
												anchorEl={anchorEl}
												open={open}
											/>

											<button
												id='basic-button'
												aria-controls={open ? "basic-menu" : undefined}
												aria-haspopup='true'
												aria-expanded={open ? "true" : undefined}
												onClick={(e) => {
													setMobileMenu(false);
													!token
														? dispatch(handleOpenAuthModal())
														: handleClick(e);
												}}
												className='w-9 h-9 group hover:bg-color_03 transition-all rounded-md bg-color_10'>
												<FaRegUser className='text-color_03 group-hover:text-white group-hover:transition-all' />
											</button>
											<div className=' hidden lg:block w-[1px] h-full min-h-[50px] bg-color_03 rounded-lg ml-2'></div>
										</div>

										<ul className=' link_footer flex justify-start items-center gap-[10px] mt-2'>
											{MobileSocialLinksItems}
										</ul>
									</Box>
								</div>
							</div>

							<div className='lg:hidden flex justify-start items-center gap-2'>
								<button
									id='mobile-basic-button'
									aria-controls={open ? "mobile-basic-button" : undefined}
									aria-haspopup='true'
									aria-expanded={open ? "true" : undefined}
									onClick={(e) => {
										token ? handleClick(e) : dispatch(handleOpenAuthModal());
										setMobileMenu(true);
									}}
									className='w-8 h-8 group hover:bg-color_03 transition-all rounded-md bg-color_10'>
									<FaRegUser className='text-color_03 group-hover:text-white group-hover:transition-all' />
								</button>
								<UserAccountMenu
									mobileMenu={mobileMenu}
									setAnchorEl={setAnchorEl}
									anchorEl={anchorEl}
									open={open}
								/>
								<button
									onClick={handleDrawerToggle}
									className='  flex justify-center items-center'>
									<MenuIcon className='!text-color_02  !text-[35px] font-medium' />
								</button>
							</div>
						</Toolbar>
					</AppBar>
					<nav className=''>
						<Drawer
							container={container}
							variant='temporary'
							open={mobileOpen}
							className=''
							onClose={handleDrawerToggle}
							ModalProps={{
								keepMounted: true, // Better open performance on mobile.
							}}
							sx={{
								display: { xs: "block", lg: "none" },
								"& .MuiDrawer-paper": {
									boxSizing: "border-box",
									width: drawerWidth,
									background: "#283078",
								},
							}}>
							{drawer}
						</Drawer>
					</nav>
				</div>
			</div>
			<AuthModal />
			<RequestCarModal />
		</>
	);
}
AppBarBox.propTypes = {
	window: PropTypes.func,
};

export default AppBarBox;
