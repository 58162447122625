import { createSlice } from "@reduxjs/toolkit";
import { RegisterThunk } from "../Thunk/RegisterThunk";
let initState = {
	reload: false,
};

const RegisterReducer = createSlice({
	name: "Register",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(RegisterThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(RegisterThunk.fulfilled, (state, action) => {
				state.reload = false;

				localStorage.setItem("name", action.meta.arg.name);
				localStorage.setItem("email", action.meta.arg.email);
				localStorage.setItem("phone", action.meta.arg.phone);
			})
			.addCase(RegisterThunk.rejected, (state, action) => {
				state.reload = false;
			});
	},
});
export default RegisterReducer.reducer;
