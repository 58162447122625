import React from "react";
import {
	Box,
	CircularProgress,
	Rating,
	Typography,
	TextField,
	Button,
	IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";
import { useDispatch, useSelector } from "react-redux";
import {
	CancelOrderThunk,
	FinishOrderThunk,
} from "../../../RTK/Thunk/CreateOrderThunk";
import { toast } from "react-toastify";

const ShowOrderInfo = ({ handleClose }) => {
	const dispatch = useDispatch();
	const { order, reload } = useSelector((state) => state.GetOrdersReducer);
	const [driverRating, setDriverRating] = React.useState(5);
	const [carRating, setCarRating] = React.useState(5);
	const [comment, setComment] = React.useState("");

	const handleSave = async () => {
		const payload = {
			comment,
			ratings: {
				driver: driverRating,
				car: carRating,
			},
		};

		dispatch(FinishOrderThunk({ id: order.id, payload }))
			.unwrap()
			.then((data) => {
				if (data?.code === 200 && data?.type === "success") {
					toast.success(data.message, { theme: "light" });
					handleClose();
				}
			})
			.catch((error) => {
				toast.error(error.message, { theme: "light" });
			});
	};

	const handleCancelOrder = async () => {
		dispatch(CancelOrderThunk({ id: order.id }))
			.unwrap()
			.then((data) => {
				if (data?.code === 200 && data?.type === "success") {
					toast.success(data.message, { theme: "light" });
					handleClose();
				}
			})
			.catch((error) => {
				toast.error(error.message, { theme: "light" });
			});
	};

	const areButtonsDisabled =
		(order?.status === 5 || order?.status === 4) && order?.status !== 3;

	return (
		<Box className='relative py-2 px-3'>
			<IconButton onClick={handleClose} className='absolute top-2 right-2'>
				<CloseIcon />
			</IconButton>
			{reload ? (
				<Box className='flex h-52 justify-center items-center'>
					<CircularProgress className='text-blue-600' />
				</Box>
			) : (
				<>
					<Typography variant='h6' className='text-center text-color_03 mb-4'>
						تفاصيل الطلب
					</Typography>
					<Box className='mb-6'>
						<Box className='flex justify-start items-center gap-1 mb-2'>
							<PersonIcon className='mr-2 text-color_03' />
							<Typography variant='h6' className='text-color_03 mb-0'>
								الاسم بالكامل
							</Typography>
						</Box>
						<TextField
							variant='outlined'
							fullWidth
							value={order?.name}
							InputProps={{
								readOnly: true,
							}}
						/>
					</Box>
					<Box className='mb-6'>
						<Box className='flex justify-start items-center gap-1 mb-2'>
							<HomeIcon className='mr-2 text-color_03' />
							<Typography variant='h6' className='text-color_03 mb-0'>
								نقل المريض من
							</Typography>
						</Box>
						<TextField
							variant='outlined'
							fullWidth
							value={order?.from}
							InputProps={{
								readOnly: true,
							}}
						/>
					</Box>
					<Box className='mb-12'>
						<Box className='flex justify-start items-center gap-1 mb-2'>
							<HomeIcon className='mr-2 text-color_03' />
							<Typography variant='h6' className='text-color_03 mb-0'>
								إلي
							</Typography>
						</Box>
						<TextField
							variant='outlined'
							fullWidth
							value={order?.to}
							InputProps={{
								readOnly: true,
							}}
						/>
					</Box>

					{order?.status !== 1 &&
						order?.status !== 3 &&
						order?.status !== 5 && (
							<>
								<Typography
									variant='h6'
									className='text-center text-color_03 mb-4'>
									تقييم الخدمة
								</Typography>
								<Box className='m-4'>
									<Typography variant='body1' className='font-medium'>
										ما رأيك بالسياره؟
									</Typography>
									<Rating
										sx={{
											"&.MuiRating-root": {
												direction: "ltr",
											},
										}}
										name='driver-rating'
										value={driverRating}
										onChange={(event, newValue) => {
											setDriverRating(newValue);
										}}
									/>
								</Box>
								<Box className='m-4'>
									<Typography variant='body1' className='font-medium'>
										ما رأيك بالسائق؟
									</Typography>
									<Rating
										sx={{
											"&.MuiRating-root": {
												direction: "ltr",
											},
										}}
										name='car-rating'
										value={carRating}
										onChange={(event, newValue) => {
											setCarRating(newValue);
										}}
									/>
								</Box>
								<Typography
									variant='body1'
									className='font-bold text-lg'
									gutterBottom>
									اضف تعليقك:
								</Typography>
								<TextField
									variant='outlined'
									fullWidth
									multiline
									rows={4}
									value={comment}
									onChange={(e) => setComment(e.target.value)}
								/>
							</>
						)}

					<Box className='mt-4 flex justify-between md:flex-row flex-col gap-4'>
						{order?.status === 2 && (
							<Button
								variant='contained'
								color='primary'
								className='md:w-max w-full text-lg font-medium'
								onClick={handleSave}
								disabled={areButtonsDisabled}>
								حفظ التقييم وانهاء الرحله
							</Button>
						)}

						<Button
							variant='outlined'
							color='error'
							onClick={handleCancelOrder}
							disabled={areButtonsDisabled}>
							إلغاء الطلب
						</Button>
					</Box>
				</>
			)}
		</Box>
	);
};

export default ShowOrderInfo;
