import React, { useEffect, useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Box,
	CircularProgress,
	IconButton,
	Modal,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
	GetOrdersThunk,
	ShowOrderByIdThunk,
} from "../../../RTK/Thunk/CreateOrderThunk";
import ShowOrderInfo from "./ShowOrderInfo"; // Adjust the path as needed
import moment from "moment";
import TablePagination from "../../../Components/TablePagination/TablePagination";

const MyRequests = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const [pageTarget, setPageTarget] = useState(1);
	const [rowsCount, setRowsCount] = useState(10);
	const [open, setOpen] = useState(false);
	const { orders, lastPage, reload, currentPage } = useSelector(
		(state) => state.GetOrdersReducer
	);

	// effects
	useEffect(() => {
		if (location.pathname === "/user-dashboard/my-orders") {
			dispatch(GetOrdersThunk({ page: pageTarget, number: rowsCount }));
		}
	}, [location, dispatch, pageTarget, rowsCount]);

	const handleEdit = (id) => {
		dispatch(ShowOrderByIdThunk({ id }));
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const getStatusText = (status) => {
		switch (status) {
			case 1:
				return (
					<div className=' border-[1px] border-solid border-orange-400 bg-orange-100  text-orange-400 w-32 px-1 m-auto py-1 rounded-xl flex justify-center items-center '>
						قيد المراجعة
					</div>
				);
			case 2:
				return (
					<div className=' border-[1px] border-solid border-blue-400 bg-blue-100  text-blue-400 w-32 px-1 m-auto py-1 rounded-xl flex justify-center items-center'>
						تمت الموافقة
					</div>
				);
			case 3:
				return (
					<div className='border-[1px] border-solid border-red-400  bg-red-100  text-red-400  w-32 px-1 m-auto py-1  rounded-xl flex justify-center items-center '>
						مرفوض
					</div>
				);
			case 4:
				return (
					<div className='border-[1px] border-solid border-green-400 bg-green-100  text-green-400 w-32 px-1 m-auto py-1  rounded-xl flex justify-center items-center '>
						مكتمل
					</div>
				);
			case 5:
				return (
					<div className=' border-[1px] border-solid border-gray-400  bg-gray-100  text-gray-400 w-32 px-1 m-auto py-1 rounded-xl flex justify-center items-center '>
						ملغي
					</div>
				);

			default:
				return (
					<div className='border-[1px] border-solid border-orange-400 bg-orange-100  text-orange-400 w-32 px-1 m-auto py-1  rounded-xl flex justify-center items-center '>
						غير معروف
					</div>
				);
		}
	};

	return (
		<TableContainer
			component={Paper}
			sx={{
				width: "auto",
				maxWidth: "95%",
				overflowX: "auto",
				marginRight: "auto",
				"@media (max-width: 600px)": {
					maxWidth: "100%",
					margin: 0,
				},
			}}>
			{reload ? (
				<Box
					sx={{
						height: "200px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<CircularProgress sx={{ color: "#658ECA" }} />
				</Box>
			) : (
				<>
					<Table sx={{ minWidth: 350 }} aria-label='simple table'>
						<TableHead>
							<TableRow sx={{ backgroundColor: "#658ECA" }}>
								<TableCell
									align='right'
									sx={{ color: "#ffffff", fontWeight: "bold" }}>
									م
								</TableCell>
								<TableCell
									align='right'
									sx={{ color: "#ffffff", fontWeight: "bold" }}>
									اسم العميل
								</TableCell>
								<TableCell
									align='right'
									sx={{ color: "#ffffff", fontWeight: "bold" }}>
									رقم الجوال
								</TableCell>

								<TableCell
									align='right'
									sx={{ color: "#ffffff", fontWeight: "bold" }}>
									تاريخ الطلب
								</TableCell>
								<TableCell
									align='center'
									sx={{ color: "#ffffff", fontWeight: "bold" }}>
									حالة الطلب
								</TableCell>
								<TableCell
									align='right'
									sx={{ color: "#ffffff", fontWeight: "bold" }}>
									الإجراء
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{orders?.map((row) => (
								<TableRow
									key={row.id}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell align='right'>{row.id}</TableCell>
									<TableCell component='th' scope='row' align='right'>
										{row.name}
									</TableCell>
									<TableCell align='right'>{row.phone}</TableCell>
									<TableCell align='right'>
										{moment(row?.created_at).format("L")}
									</TableCell>
									<TableCell align='center'>
										{getStatusText(row.status)}
									</TableCell>
									<TableCell align='right'>
										<IconButton
											onClick={() => handleEdit(row?.id)}
											color='primary'>
											<EditIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<Box sx={{ p: 2 }}>
						{orders?.length !== 0 && (
							<TablePagination
								pageCount={lastPage}
								rowsCount={rowsCount}
								currentPage={currentPage}
								setPageTarget={setPageTarget}
								setRowsCount={setRowsCount}
							/>
						)}
					</Box>
				</>
			)}
			<Modal open={open} onClose={handleClose} className=' overflow-y-auto'>
				<Box
					sx={{
						position: "absolute",
						top: "20px",
						left: "50%",
						transform: "translate(-50%, 0%)",
						width: "60%",
						bgcolor: "background.paper",
						boxShadow: 24,
						borderRadius: "10px",
						p: 4,
					}}>
					<ShowOrderInfo handleClose={handleClose} />
				</Box>
			</Modal>
		</TableContainer>
	);
};

export default MyRequests;
