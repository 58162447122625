import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

const AgreeToResponsibilityModal = ({ open, onClose, onAgree }) => {
	return (
		<Modal open={open} onClose={onClose} className='overflow-auto'>
			<Box className='bg-white p-4 mx-auto mt-16 w-[95%] md:w-1/2 rounded-lg'>
				<Typography variant='h6' className='text-center !mb-5'>
					إقرار المسئولة
				</Typography>
				<Typography variant='bod1' className='!mb-5 text-lg '>
					اتعهد بأن جميع البيانات صحيحة وأن نطاق الطلب في المنطقة الشرقية ضمن
					نطاق خدمات الجمعية وخلاف ذلك سيتم إلغاء الطلب
				</Typography>
				<Box className='flex justify-end'>
					<Button variant='contained' color='primary' onClick={onAgree}>
						موافق
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default AgreeToResponsibilityModal;
