import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
	List,
	ListItem,
	ListItemText,
	Box,
	styled,
	Tabs,
	Tab,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { TbPasswordFingerprint } from "react-icons/tb";
import { AccountBox, CardTravelRounded } from "@mui/icons-material";

const StyledList = styled(List)({
	height: "auto",
	backgroundColor: "white",
	borderRadius: "8px",
	boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
	width: "100%",
	paddingTop: 0, // Remove padding at the top if any
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
	padding: "10px 20px",
	borderRadius: "4px",
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-start",
	color: theme.palette.text.primary,
	"&:hover": {
		backgroundColor: "#658ECA",
		color: "#ffffff",
	},
}));

const StyledListItemText = styled(ListItemText)({
	textAlign: "right",
	paddingRight: "10px",
});

function VerticalMenu() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const location = useLocation();

	const getTabProps = (to) => ({
		component: NavLink,
		to,
		style: {
			display: "flex",
			gap: "10px",
			alignItems: "center",
			flexDirection: "row",
			justifyContent: "center",
			textDecoration: "none",
			borderRadius: "6px",
			minHeight: "50px",
			minWidth: "max-content",
			color: location.pathname?.slice(16) === to ? "#fff" : "#658ECA",
			backgroundColor:
				location.pathname?.slice(16) === to ? "#658ECA" : "transparent",
		},
	});

	return (
		<Box sx={{ width: isMobile ? "100%" : 240 }}>
			{isMobile ? (
				<Tabs
					sx={{
						"& .MuiTabs-flexContainer": {
							width: "100%",
							overflowX: "auto",
							marginBottom: "10px",
						},
					}}
					value={location.pathname}
					centered
					variant='fullWidth'>
					<Tab
						icon={<AccountBox />}
						label='تعديل الملف الشخصي'
						{...getTabProps("profile")}
					/>
					<Tab
						icon={<TbPasswordFingerprint />}
						label='تغيير كلمة المرور'
						{...getTabProps("change-password")}
					/>

					<Tab
						icon={<CardTravelRounded />}
						label='طلباتي'
						{...getTabProps("my-orders")}
					/>
				</Tabs>
			) : (
				<StyledList sx={{ padding: "16px 5px" }}>
					<NavLink
						to='profile'
						className={({ isActive }) => (isActive ? "activeLink" : "")}
						style={{ textDecoration: "none" }}>
						<StyledListItem button>
							<AccountBox />
							<StyledListItemText primary='تعديل الملف الشخصي' />
						</StyledListItem>
					</NavLink>
					<NavLink
						to='change-password'
						className={({ isActive }) => (isActive ? "activeLink" : "")}
						style={{ textDecoration: "none" }}>
						<StyledListItem button>
							<TbPasswordFingerprint />
							<StyledListItemText primary='تغيير كلمة المرور' />
						</StyledListItem>
					</NavLink>
					<NavLink
						to='my-orders'
						className={({ isActive }) => (isActive ? "activeLink" : "")}
						style={{ textDecoration: "none" }}>
						<StyledListItem button>
							<CardTravelRounded />
							<StyledListItemText primary='طلباتي' />
						</StyledListItem>
					</NavLink>
				</StyledList>
			)}
		</Box>
	);
}

export default VerticalMenu;
