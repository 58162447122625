import { configureStore } from "@reduxjs/toolkit";

import MainPageSectionsStatusReducer from "./Reducers/MainPageSectionsStatusReducer";
import ElectronicServicesReducer from "./Reducers/ElectronicServicesReducer";
import SectionsOfPagesReducer from "./Reducers/SectionsOfPagesReducer";
import OurAchievementsReducer from "./Reducers/OurAchievementsReducer";
import AboutUsSectionReducer from "./Reducers/AboutUsSectionReducer";
import MainPageNamesReducer from "./Reducers/MainPageNamesReducer";
import TestimonialsReducer from "./Reducers/TestimonialsReducer";
import ContactInfoReducer from "./Reducers/ContactInfoReducer";
import SocialLinksReducer from "./Reducers/SocialLinksReducer";
import CreateOrderReducer from "./Reducers/CreateOrderReducer";
import MainSectionReducer from "./Reducers/MainSectionReducer";
import PhotoAlbumReducer from "./Reducers/PhotoAlbumReducer";
import VideoAlbumReducer from "./Reducers/VideoAlbumReducer";
import LastNewsReducer from "./Reducers/LastNewsReducer";
import SiteLogoReducer from "./Reducers/SiteLogoReducer";
import PartnersReducer from "./Reducers/PartnersReducer";
import RegisterReducer from "./Reducers/RegisterReducer";
import PagesReducer from "./Reducers/PagesReducer";
import LoginReducer from "./Reducers/LoginReducer";
import EditProfileReducer from "./Reducers/EditProfileReducer";
import GetOrdersReducer from "./Reducers/GetOrdersReducer";
import ChangePasswordReducer from "./Reducers/ChangePasswordReducer";

export const Store = configureStore({
	reducer: {
		MainPageSectionsStatusReducer,
		ElectronicServicesReducer,
		SectionsOfPagesReducer,
		OurAchievementsReducer,
		ChangePasswordReducer,
		AboutUsSectionReducer,
		MainPageNamesReducer,
		CreateOrderReducer,
		TestimonialsReducer,
		SocialLinksReducer,
		MainSectionReducer,
		EditProfileReducer,
		ContactInfoReducer,
		PhotoAlbumReducer,
		VideoAlbumReducer,
		PartnersReducer,
		LastNewsReducer,
		GetOrdersReducer,
		SiteLogoReducer,
		RegisterReducer,
		LoginReducer,
		PagesReducer,
	},
});
