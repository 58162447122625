import React, { useEffect } from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useDispatch, useSelector } from "react-redux";
import { ContactInfoThunk } from "../../RTK/Thunk/ContactInfoThunk";
import { SiteLogoThunk } from "../../RTK/Thunk/SiteLogoThunk";
const WhatsAppBox = () => {
	const dispatch = useDispatch();
	const { contactInfo } = useSelector((state) => state.ContactInfoReducer);

	const { siteLogo } = useSelector((state) => state.SiteLogoReducer);

	useEffect(() => {
		const debounce = setTimeout(() => {
			dispatch(ContactInfoThunk());

			dispatch(SiteLogoThunk());
		}, 0);
		return () => {
			clearTimeout(debounce);
		};
	}, []);

	const chatMessage = () => {
		return <p>مرحبا بك، كيف يمكننا مساعدتك؟</p>;
	};
	return (
		<>
			<div className='' style={{ direction: "ltr" }}>
				<FloatingWhatsApp
					accountName='جمعية نعاون '
					placeholder={"...يرجي ترك رسالتك"}
					statusMessage={
						"يمكنك التحدث الى خدمه العملاء لتقديم المساعدة أثناء التصفح أونلاين"
					}
					avatar={siteLogo}
					chatMessage={chatMessage().props.children}
					allowEsc
					notification
					allowClickAway
					notificationSound
					phoneNumber={
						!contactInfo?.phone?.includes("+966") ||
						!contactInfo?.phone?.includes("00966")
							? `+966${contactInfo?.phone}`
							: contactInfo?.phone
					}
				/>
			</div>
		</>
	);
};

export default WhatsAppBox;
