import { createSlice } from "@reduxjs/toolkit";
import { LoginThunk } from "../Thunk/LoginThunk";

let initState = {
	reload: false,
};

const LoginReducer = createSlice({
	name: "Login",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(LoginThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(LoginThunk.fulfilled, (state, action) => {
				state.reload = false;
				localStorage.setItem("nueawin_token", action.payload.data.token);
				localStorage.setItem("avatar", action.payload.data.avatar);
				localStorage.setItem("name", action.payload.data.name);
			})
			.addCase(LoginThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.error.message;
			});
	},
});
export default LoginReducer.reducer;
