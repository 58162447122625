import { createBrowserRouter } from "react-router-dom";

import AboutPage from "./Pages/AboutPage";
import HomePage from "./Pages/HomePage";
import Layout from "./Pages/Layout";
import NewsPage from "./Pages/NewsPage";
import PhotoAlbumsPage from "./Pages/PhotoAlbumsPage";
import VideoAlbumsPage from "./Pages/VideoAlbumsPage";
import TestimonialPage from "./Pages/TestimonialPage";
import PartnersPage from "./Pages/PartnersPage";
import ContactUsPage from "./Pages/ContactUsPage";
import PagesCreator from "./Pages/PagesCreator";
import NewsDetailsPage from "./Pages/NewsDetailsPage";
import SupPage from "./Pages/SupPage";
import UserDashboard from "./Pages/UserDashboard/UserDashboard";
import MyRequests from "./Pages/UserDashboard/MyRequests";
import EditUserProfile from "./Pages/UserDashboard/EditUserProfile";
import ChangePassword from "./Pages/UserDashboard/ChangePassword";

export let Router = createBrowserRouter([
	{
		path: "/",
		element: <Layout />,
		errorElement: <div>errorElement</div>,

		children: [
			{ index: true, element: <HomePage /> },

			{
				path: "/about",
				element: <AboutPage />,
			},
			{
				path: "/news",
				element: <NewsPage />,
			},
			{
				path: "/news/newsDetails/:id",
				element: <NewsDetailsPage />,
			},
			{
				path: "/photo-albums",
				element: <PhotoAlbumsPage />,
			},
			{
				path: "/video-albums",
				element: <VideoAlbumsPage />,
			},
			{
				path: "/testimonial-page",
				element: <TestimonialPage />,
			},
			{
				path: "/partners-page",
				element: <PartnersPage />,
			},
			{
				path: "/contact-us",
				element: <ContactUsPage />,
			},

			{
				path: "/PagesCreator/:id/:title",
				element: <PagesCreator />,
			},
			{
				path: "user-dashboard",
				element: <UserDashboard />,
				children: [
					{ index: true, element: <EditUserProfile /> },
					{ path: "profile", element: <EditUserProfile /> },
					{ path: "my-orders", element: <MyRequests /> },
					{ path: "change-password", element: <ChangePassword /> },
				],
			},

			{
				path: "/SupPage/:id/:title",
				element: <SupPage />,
			},
		],
	},
]);
