import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const CreateOrderThunk = createAsyncThunk(
	"CreateOrderThunk",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;
		const token = localStorage.getItem("nueawin_token");
		try {
			const res = await axios.post(
				"https://api.nueawin.com/api/users/orders",
				arg,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return res?.data;
		} catch (error) {
			if (error.response) {
				return rejectWithValue(error.response.data);
			} else {
				// Handle cases where the error is not from the server
				return rejectWithValue({ message: "An unexpected error occurred" });
			}
		}
	}
);

export const GetOrdersThunk = createAsyncThunk(
	"GetOrdersThunk",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;
		const token = localStorage.getItem("nueawin_token");
		try {
			const res = await axios.get(
				`https://api.nueawin.com/api/users/orders?page=${arg.page}&per_page=${arg.number}`,

				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return res?.data;
		} catch (error) {
			if (error.response) {
				return rejectWithValue(error.response.data);
			} else {
				// Handle cases where the error is not from the server
				return rejectWithValue({ message: "An unexpected error occurred" });
			}
		}
	}
);

export const ShowOrderByIdThunk = createAsyncThunk(
	"ShowOrderByIdThunk",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;
		const token = localStorage.getItem("nueawin_token");
		try {
			const res = await axios.get(
				`https://api.nueawin.com/api/users/orders/${arg.id}`,

				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return res?.data;
		} catch (error) {
			if (error.response) {
				return rejectWithValue(error.response.data);
			} else {
				// Handle cases where the error is not from the server
				return rejectWithValue({ message: "An unexpected error occurred" });
			}
		}
	}
);

export const CancelOrderThunk = createAsyncThunk(
	"order/cancel",
	async ({ id }, { rejectWithValue }) => {
		const token = localStorage.getItem("nueawin_token");
		try {
			const response = await axios.post(
				`https://api.nueawin.com/api/users/orders/${id}/cancel`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			if (error.response && error.response.data) {
				// Server-side error
				return rejectWithValue(error.response.data);
			} else {
				// Client-side or network error
				return rejectWithValue({ message: "An unexpected error occurred" });
			}
		}
	}
);

export const FinishOrderThunk = createAsyncThunk(
	"order/finish",

	async ({ id, payload }, { rejectWithValue }) => {
		const token = localStorage.getItem("nueawin_token");
		try {
			const response = await axios.post(
				`https://api.nueawin.com/api/users/orders/${id}/finish`,
				payload,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			} else {
				return rejectWithValue({ message: "An unexpected error occurred" });
			}
		}
	}
);
