import { ExpandMore } from "@mui/icons-material";
import { Dropdown, Space } from "antd";
import React, { useMemo, useState } from "react";
import "./DropDownBox.css";
import { useEffect } from "react";
import { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SectionsOfPagesThunk } from "../../RTK/Thunk/SectionsOfPagesThunk";
import { setCurrentPageId } from "../../RTK/Reducers/PagesReducer";

const DropDownBoxInHeader = ({
	title,
	items,
	icon,
	id,
	StatusItem,
	HasSection,
}) => {
	// console.log("StatusItem",StatusItem);
	const [changeIcon, setChangeIcon] = useState(false);
	const dispatch = useDispatch();

	const handleDropdown = (el) => {
		// console.log("el",el)
		if (el?.length) {
			let data = [...el];
			data = data.map((item, i) => {
				if (item?.status && item?.has_sections) {
					return {
						key: i + 1,
						link: `/SupPage/${item?.id}/${item?.name}`,
						label: (
							<NavLink
								to={`/SupPage/${item?.id}/${item?.name}`}
								onClick={() => {
									dispatch(SectionsOfPagesThunk(item?.id));
									dispatch(setCurrentPageId(item));
								}}
								className='group min-w-[170px] flex justify-start transition-[0.3s] hover:pr-[10px] items-center gap-[5px]'>
								{/* <FaPhotoFil className="group-hover:text-color_03 transition-[0.3s]" /> */}
								<div className=' group-hover:text-color_03 transition-[0.3s]  w-[23px] h-[23px] flex justify-center items-center  '>
									<img
										src={item?.icon}
										alt={item?.icon}
										className='max-h-full max-w-full object-contain'
									/>
								</div>
								<h5 className='group-hover:text-color_03 transition-[0.3s] group-hover:font-[600] '>
									{item?.name}
								</h5>
							</NavLink>
						),
					};
				}
			});
			// return data
			return (
				<>
					<Dropdown
						menu={{
							items: data,
						}}
						onOpenChange={(e) => {
							setChangeIcon(e);
						}}
						className={` ${
							changeIcon && "active"
						} DropDownBox nav_link_max min-w-[100px] text-center flex justify-center items-center  transition-[0.3s]  text-lg font-medium px-4 py-1 rounded `}>
						{StatusItem && HasSection ? (
							<NavLink
								to={`/PagesCreator/${id}/${title}`}
								onClick={() => {
									// handleCloseMenu();
									dispatch(SectionsOfPagesThunk(id));
									// dispatch(setCurrentPageId(subPage));
								}}>
								<Space className=' text-color_06'>
									<div className='!text-[16px] flex justify-start items-center gap-[5px] !font-medium cursor-pointer'>
										<div className='  w-[23px] h-[23px] flex justify-center items-center  '>
											<img
												src={icon}
												alt={icon}
												className='max-h-full max-w-full object-contain'
											/>
										</div>
										{title}
									</div>
									<ExpandMore
										className={`!text-[22px] icon_rotate !font-medium`}
									/>
								</Space>
							</NavLink>
						) : (
							<div>
								<Space className=' text-color_06'>
									<div className='!text-[16px] flex justify-start items-center gap-[5px] !font-medium cursor-pointer'>
										<div className='  w-[23px] h-[23px] flex justify-center items-center  '>
											<img
												src={icon}
												alt={icon}
												className='max-h-full max-w-full object-contain'
											/>
										</div>
										{title}
									</div>
									<ExpandMore
										className={`!text-[22px] icon_rotate !font-medium`}
									/>
								</Space>
							</div>
						)}
					</Dropdown>
				</>
			);
		} else {
			return (
				StatusItem &&
				HasSection && (
					<NavLink
						to={`/PagesCreator/${id}/${title}`}
						onClick={() => {
							// handleCloseMenu();
							dispatch(SectionsOfPagesThunk(id));
							// dispatch(setCurrentPageId(subPage));
						}}
						className={` ${
							changeIcon && "active"
						} DropDownBox nav_link_max min-w-[100px] text-center flex justify-center items-center  transition-[0.3s]  text-lg font-medium px-4 py-1 rounded `}>
						<Space className=' text-color_06'>
							<div className='!text-[16px] flex justify-start items-center gap-[5px] !font-medium cursor-pointer'>
								<div className='  w-[23px] h-[23px] flex justify-center items-center  '>
									<img
										src={icon}
										alt={icon}
										className='max-h-full max-w-full object-contain'
									/>
								</div>
								{title}
							</div>
							{/* <ExpandMore className={`!text-[22px] icon_rotate !font-medium`} /> */}
						</Space>
					</NavLink>
				)
			);
		}
	};
	return <>{handleDropdown(items)}</>;
};
export default React.memo(DropDownBoxInHeader);
