import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CreateOrderThunk } from "../../RTK/Thunk/CreateOrderThunk";
import {
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormLabel,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
} from "@mui/material";
import { Error } from "@mui/icons-material";
import { handleCloseRequestCarModal } from "../../RTK/Reducers/CreateOrderReducer";
import { IoIosArrowDown } from "react-icons/io";
import FacilitiesForm from "./FacilitiesForm";
import AgreeToResponsibilityModal from "./AgreeToResponsibilityModal"; // Ensure this import path is correct

const textFieldStyle = {
	"& .MuiOutlinedInput-notchedOutline": {
		textAlign: "right",
	},
	"& .MuiInputLabel-root": {
		left: "auto",
		right: "29px",
		transformOrigin: "top right",
	},

	"& .MuiOutlinedInput-root": {
		"@media (max-width: 600px)": {
			height: "46px",
		},
	},
};
const ageOfBeneficiaryArray = [
	{
		id: 1,
		title: "أقل من ١٠ سنوات",
	},
	{
		id: 2,
		title: "من ١٠ إلي ٢٠ سنة",
	},
	{
		id: 3,
		title: "من ٢٠ إلي ٣٠ سنة",
	},
	{
		id: 4,
		title: "من ٣٠ إلي ٤٠ سنة",
	},
	{
		id: 5,
		title: "من ٤٠ إلي ٥٠ سنة",
	},
	{
		id: 6,
		title: "من ٥٠ إلي ٦٠ سنة",
	},
	{
		id: 7,
		title: " أكبر من  ٦٠ سنة",
	},
];

const RequestCarModal = () => {
	// handle open or close Auth modal and Request car modal
	const { openRequestCarModal } = useSelector(
		(state) => state.CreateOrderReducer
	);
	const dispatch = useDispatch();
	const { reload } = useSelector((state) => state.CreateOrderReducer);

	const [isAgree, setIsAgree] = useState(false);
	const [userType, setUserType] = useState("handicapped");
	const [companionGender, setCompanionGender] = useState("male");
	const [beneficiaryGender, setBeneficiaryGender] = useState("male");
	const [showResponsibilityModal, setShowResponsibilityModal] = useState(false);
	const [isThereCompanionForPatient, setIsThereCompanionForPatient] =
		useState("no");

	const [formData, setFormData] = useState({
		name: localStorage.getItem("name") || "",
		phone: localStorage.getItem("phone") || "",
		description: "",
		from: "",
		to: "",
		age: "",
		other_user_type: "",
		companionAge: "",
		companionName: "",
		companionNationality: "",
		purposeTransferRequest: "",
	});

	// Handle gender of Beneficiary
	const handleBeneficiaryGenderChange = (event) => {
		setBeneficiaryGender(event.target.value);
	};

	// Handle user type
	const handleUserTypeChange = (event) => {
		setUserType(event.target.value);
	};

	// Handle Companion Gender
	const handleCompanionGenderChange = (event) => {
		setCompanionGender(event.target.value);
	};

	const handleIsThereCompanionForPatientChange = (event) => {
		setIsThereCompanionForPatient(event.target.value);
	};

	const handleInputChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const [errors, setErrors] = useState({
		mainError: "",
		name: "",
		phone: "",
		from: "",
		to: "",

		age: "",
		other_user_type: "",
		companionAge: "",
		companionName: "",
		companionNationality: "",
		purposeTransferRequest: "",
		description: "",
	});

	const resetError = () => {
		setErrors({
			mainError: "",
			name: "",
			phone: "",
			from: "",
			to: "",

			age: "",
			other_user_type: "",
			companionAge: "",
			companionName: "",
			companionNationality: "",
			purposeTransferRequest: "",
			description: "",
		});
	};

	const resetData = () => {
		setFormData({
			name: "",
			phone: "",
			description: "",
			from: "",
			to: "",
			age: "",
			other_user_type: "",
			companionAge: "",
			companionName: "",
			companionNationality: "",
			purposeTransferRequest: "",
		});
		setIsAgree(false);
		setUserType("handicapped");
		setCompanionGender("male");
		setBeneficiaryGender("male");
		setShowResponsibilityModal(false);
		setIsThereCompanionForPatient("no");
	};

	const handleCreateOrder = async () => {
		resetError();

		const orderData = new FormData();
		orderData.append("name", formData.name);
		orderData.append("phone", formData.phone);
		orderData.append("from", formData.from);
		orderData.append("to", formData.to);
		orderData.append("age", formData.age);
		orderData.append("gender", beneficiaryGender);
		orderData.append("user_type", userType);

		if (userType === "other") {
			orderData.append("other_user_type", formData.other_user_type);
		}
		orderData.append("purpose", formData.purposeTransferRequest);
		orderData.append(
			"has_companion",
			isThereCompanionForPatient === "yes" ? 1 : 0
		);

		if (isThereCompanionForPatient === "yes") {
			orderData.append("companion_name", formData.companionName);
			orderData.append("companion_age", formData.companionAge);
			orderData.append("companion_nationality", formData.companionNationality);
			orderData.append("companion_gender", companionGender);
		}
		orderData.append("description", formData.description);

		dispatch(CreateOrderThunk(orderData))
			.unwrap()
			.then((data) => {
				if (data?.code === 201 && data?.type === "success") {
					toast.success(data.message, { theme: "light" });
					resetData();
					dispatch(handleCloseRequestCarModal());
				}
			})
			.catch((error) => {
				setErrors({
					...errors,
					mainError: error?.data?.message,
					name: error?.data?.name,
					phone: error?.data?.phone,
					age: error?.data?.age,
					from: error?.data?.from,
					to: error?.data?.to,
					beneficiaryGender: error?.data?.beneficiaryGender,
					description: error?.data?.description,

					other_user_type: error?.data?.other_user_type,
					companionAge: error?.data?.companionAge,
					companionName: error?.data?.companionName,
					companionNationality: error?.data?.companionNationality,
					purposeTransferRequest: error?.data?.purposeTransferRequest,
				});

				toast.error(error.message, { theme: "light" });
			});
	};

	const handleCheckboxChange = (event) => {
		setShowResponsibilityModal(event.target.checked);
		if (!event.target.checked) {
			setIsAgree(false);
		}
	};

	const handleAgree = () => {
		setIsAgree(true);
		setShowResponsibilityModal(false);
	};

	return (
		<>
			<Modal
				open={openRequestCarModal}
				onClose={() => dispatch(handleCloseRequestCarModal())}
				className='overflow-auto'>
				<Box className='bg-white p-4 mx-auto mt-16 w-[95%] md:w-1/2 rounded-lg'>
					<IconButton
						onClick={() => dispatch(handleCloseRequestCarModal())}
						className='absolute top-2 right-2'>
						<IoCloseSharp />
					</IconButton>

					<h2 className='text-center text-lg font-bold mb-4'>
						نموذج طلب سيارة
					</h2>

					<Box p={3}>
						{/* Full name input */}
						<>
							<TextField
								sx={textFieldStyle}
								label='الاسم بالكامل'
								type='text'
								name='name'
								value={formData.name}
								onChange={handleInputChange}
								fullWidth
								margin='normal'
							/>
							{errors?.name && (
								<Box className='flex justify-start items-center'>
									<Error sx={{ color: "red", fontSize: "18px" }} />
									<p className='text-red-500'>{errors?.name}</p>
								</Box>
							)}
						</>

						{/* Phone input */}
						<>
							<TextField
								sx={textFieldStyle}
								label='رقم الجوال'
								type='text'
								name='phone'
								value={formData.phone}
								onChange={handleInputChange}
								fullWidth
								margin='normal'
							/>
							{errors?.phone && (
								<Box className='flex justify-start items-center'>
									<Error sx={{ color: "red", fontSize: "18px" }} />
									<p className='text-red-500'>{errors?.phone}</p>
								</Box>
							)}
						</>

						{/* Address input */}
						<>
							<TextField
								sx={textFieldStyle}
								label='طلب نقل من '
								type='text'
								name='from'
								value={formData.from}
								onChange={handleInputChange}
								fullWidth
								margin='normal'
							/>
							{errors?.from && (
								<Box className='flex justify-start items-center'>
									<Error sx={{ color: "red", fontSize: "18px" }} />
									<p className='text-red-500'>{errors?.from}</p>
								</Box>
							)}
							<TextField
								sx={textFieldStyle}
								label='إلي'
								type='text'
								name='to'
								value={formData.to}
								onChange={handleInputChange}
								fullWidth
								margin='normal'
							/>
							{errors?.to && (
								<Box className='flex justify-start items-center'>
									<Error sx={{ color: "red", fontSize: "18px" }} />
									<p className='text-red-500'>{errors?.to}</p>
								</Box>
							)}
						</>

						{/* Gender Of Beneficiary */}
						<>
							<FormControl className='!mt-4'>
								<FormLabel id='demo-radio-buttons-group-label'>
									جنس المستفيد
								</FormLabel>
								<RadioGroup
									sx={{
										"&.MuiFormGroup-root": {
											display: "flex",
											flexDirection: "row",
										},
									}}
									defaultValue='male'
									value={beneficiaryGender}
									onChange={handleBeneficiaryGenderChange}>
									<FormControlLabel
										sx={{
											"&.MuiFormControlLabel-root": {
												marginRight: "-10px",
											},
										}}
										value='male'
										control={<Radio />}
										label='ذكر'
									/>

									<FormControlLabel
										value='female'
										control={<Radio />}
										label='أنثي'
									/>
								</RadioGroup>
							</FormControl>
							{errors?.beneficiaryGender && (
								<Box className='flex justify-start items-center'>
									<Error sx={{ color: "red", fontSize: "18px" }} />
									<p className='text-red-500'>{errors?.beneficiaryGender}</p>
								</Box>
							)}
						</>

						{/* Select age of beneficiary */}
						<>
							<FormControl
								fullWidth
								margin='normal'
								sx={{
									marginBottom: "30px",
									"& .MuiOutlinedInput-notchedOutline": {
										textAlign: "right",
									},
									"& .MuiInputLabel-root": {
										left: "auto",
										right: "29px",
										transformOrigin: "top right",
									},
								}}>
								<InputLabel id='age-label'>عُمْر المستفيد</InputLabel>
								<Select
									id='age'
									name='age'
									labelId='age-label'
									value={formData.age}
									label='اختر عُمْر المستفيد'
									onChange={handleInputChange}
									IconComponent={IoIosArrowDown}
									sx={{
										"& .MuiSelect-icon": {
											right: "auto",
											left: 8,
										},
									}}>
									{ageOfBeneficiaryArray?.map((item) => (
										<MenuItem value={item?.title} key={item?.id}>
											{item?.title}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							{errors?.purposeTransferRequest && (
								<Box className='flex justify-start items-center'>
									<Error sx={{ color: "red", fontSize: "18px" }} />
									<p className='text-red-500'>
										{errors?.purposeTransferRequest}
									</p>
								</Box>
							)}
						</>

						{/* User type */}
						<>
							<FormControl className='mt-4 w-full'>
								<FormLabel>نوع المستفيد</FormLabel>
								<RadioGroup
									sx={{
										"& .MuiFormControlLabel-root": {
											marginRight: "-10px",
										},
									}}
									value={userType}
									defaultValue='handicapped'
									onChange={handleUserTypeChange}>
									<FormControlLabel
										value='handicapped'
										control={<Radio />}
										label='معاق'
									/>

									<FormControlLabel
										value='old'
										control={<Radio />}
										label='كبير سن'
									/>

									<FormControlLabel
										value='other'
										control={<Radio />}
										label='أخري'
									/>
								</RadioGroup>
								{userType === "other" && (
									<>
										<TextField
											fullWidth
											type='text'
											margin='normal'
											className='w-full'
											sx={textFieldStyle}
											name='other_user_type'
											onChange={handleInputChange}
											label='قم بكتابة نوع المستفيد'
											value={formData.other_user_type}
										/>
										{errors?.other_user_type && (
											<Box className='flex justify-start items-center'>
												<Error sx={{ color: "red", fontSize: "18px" }} />
												<p className='text-red-500'>
													{errors?.other_user_type}
												</p>
											</Box>
										)}
									</>
								)}
							</FormControl>
							{errors?.userType && (
								<Box className='flex justify-start items-center'>
									<Error sx={{ color: "red", fontSize: "18px" }} />
									<p className='text-red-500'>{errors?.userType}</p>
								</Box>
							)}
						</>

						{/* purposeTransferRequest */}
						<>
							<TextField
								sx={{
									"& .MuiOutlinedInput-notchedOutline": {
										textAlign: "right",
									},
									"& .MuiInputLabel-root": {
										left: "auto",
										right: "29px",
										transformOrigin: "top right",
									},
								}}
								rows={4}
								type='text'
								fullWidth
								margin='normal'
								name='purposeTransferRequest'
								label='الغرض من طلب النقل'
								onChange={handleInputChange}
								value={formData.purposeTransferRequest}
							/>

							{errors?.purposeTransferRequest && (
								<Box className='flex justify-start items-center'>
									<Error sx={{ color: "red", fontSize: "18px" }} />
									<p className='text-red-500'>
										{errors?.purposeTransferRequest}
									</p>
								</Box>
							)}
						</>

						<Box className='mt-4'>
							<FormControl>
								<FormLabel id='demo-radio-buttons-group-label'>
									هل يوجد مرافق للمستفيد؟
								</FormLabel>
								<RadioGroup
									className='flex justify-start !flex-row'
									defaultValue={false}
									value={isThereCompanionForPatient}
									onChange={handleIsThereCompanionForPatientChange}>
									<FormControlLabel
										sx={{ marginRight: "-10px" }}
										value={"yes"}
										name='yes'
										control={<Radio />}
										label='نعم يوجد مرافق '
									/>

									<FormControlLabel
										value={"no"}
										name='no'
										control={<Radio />}
										label='لا يوجد مرافق '
									/>
								</RadioGroup>
							</FormControl>

							{errors?.isThereCompanionForPatient && (
								<Box className='flex justify-start items-center'>
									<Error sx={{ color: "red", fontSize: "18px" }} />
									<p className='text-red-500'>
										{errors?.isThereCompanionForPatient}
									</p>
								</Box>
							)}
						</Box>

						{isThereCompanionForPatient === "yes" && (
							<FacilitiesForm
								errors={errors}
								companionGender={companionGender}
								handleInputChange={handleInputChange}
								companionAge={formData.companionAge}
								companionName={formData.companionName}
								ageOfBeneficiaryArray={ageOfBeneficiaryArray}
								handleCompanionGenderChange={handleCompanionGenderChange}
								companionNationality={formData.companionNationality}
							/>
						)}

						<TextField
							sx={{
								"& .MuiOutlinedInput-notchedOutline": {
									textAlign: "right",
								},
								"& .MuiInputLabel-root": {
									left: "auto",
									right: "29px",
									transformOrigin: "top right",
								},
							}}
							label='ملاحظات'
							type='text'
							name='description'
							value={formData.description}
							onChange={handleInputChange}
							fullWidth
							margin='normal'
							multiline
							rows={4}
						/>

						{errors?.description && (
							<Box className='flex justify-start items-center'>
								<Error sx={{ color: "red", fontSize: "18px" }} />
								<p className='text-red-500'>{errors?.description}</p>
							</Box>
						)}

						<Box>
							<FormControlLabel
								sx={{ marginRight: "0" }}
								control={
									<Checkbox
										checked={showResponsibilityModal || isAgree}
										onChange={handleCheckboxChange}
									/>
								}
								label='الموافقه علي اقرار المسئولة'
							/>
						</Box>

						{errors?.mainError && (
							<Box className='flex justify-start items-center'>
								<Error sx={{ color: "red", fontSize: "18px" }} />
								<p className='text-red-500'>{errors?.mainError}</p>
							</Box>
						)}

						<button
							onClick={handleCreateOrder}
							disabled={reload || !isAgree}
							className='mt-4 w-full bg-color_01 disabled:bg-color_03 hover:bg-color_03 text-white rounded-md h-12 text-lg font-medium'>
							{reload ? (
								<CircularProgress sx={{ color: "#fff" }} />
							) : (
								"	تأكيد الطلب"
							)}
						</button>
					</Box>
				</Box>
			</Modal>

			<AgreeToResponsibilityModal
				open={showResponsibilityModal}
				onClose={() => setShowResponsibilityModal(false)}
				onAgree={handleAgree}
			/>
		</>
	);
};

export default RequestCarModal;
