import React, { useState, useRef, useEffect } from "react";
import {
	TextField,
	Button,
	Avatar,
	Box,
	CircularProgress,
} from "@mui/material";

import { Error } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
	EditProfileThunk,
	ProfileThunk,
} from "../../../RTK/Thunk/EditProfileThunk";
import { useLocation } from "react-router-dom";

const textFieldStyle = {
	"& .MuiOutlinedInput-notchedOutline": {
		textAlign: "right",
	},
	"& .MuiInputLabel-root": {
		left: "auto",
		right: "29px",
		transformOrigin: "top right",
	},

	"& .MuiOutlinedInput-root": {
		"@media (max-width: 600px)": {
			height: "46px",
		},
	},
};
const EditUserProfile = () => {
	const dispatch = useDispatch();

	const location = useLocation();
	const { reload, profile } = useSelector((state) => state.EditProfileReducer);
	const [imagePreview, setImagePreview] = useState(null);
	const [profileFormData, setProfileFormData] = useState({
		name: "",
		email: "",
		phone: "",
		avatar: null,
	});

	// effects
	useEffect(() => {
		if (
			location.pathname === "/user-dashboard" ||
			location.pathname === "/user-dashboard/profile"
		) {
			dispatch(ProfileThunk());
		}
	}, [location, dispatch]);

	//
	useEffect(() => {
		if (profile) {
			setProfileFormData({
				...profileFormData,
				name: profile?.name,
				email: profile?.email,
				phone: profile?.phone,
				avatar: null,
			});
			setImagePreview(profile?.avatar ? profile?.avatar : null);
		}
	}, [profile]);

	const [errors, setErrors] = useState({
		mainError: "",
		name: "",
		email: "",
		phone: "",
		avatar: "",
	});

	const fileInputRef = useRef(null);

	const resetError = () => {
		setErrors({
			mainError: "",
			name: "",
			email: "",
			phone: "",
			avatar: "",
		});
	};

	const handleInputChange = (e) => {
		setProfileFormData({
			...profileFormData,
			[e.target.name]: e.target.value,
		});
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setImagePreview(URL.createObjectURL(file));
		} else {
			setImagePreview(profile?.avatar ? profile?.avatar : null);
		}
		setProfileFormData({
			...profileFormData,
			avatar: file,
		});
	};

	const handleEditProfile = async () => {
		resetError();

		const formData = new FormData();
		formData.append("name", profileFormData.name);
		formData.append("email", profileFormData.email);
		formData.append("phone", profileFormData.phone);
		if (profileFormData.avatar) {
			formData.append("avatar", profileFormData.avatar);
		}
		dispatch(EditProfileThunk(formData))
			.unwrap()
			.then((data) => {
				if (data?.code === 200 && data?.type === "success") {
					toast.success(data.message, { theme: "light" });
				}
			})

			.catch((error) => {
				setErrors({
					...errors,
					mainError: error?.data?.message,
					name: error?.data?.name,
					email: error?.data?.email,
					phone: error?.data?.phone,
					avatar: error?.data?.avatar,
				});

				toast.error(error.message, { theme: "light" });
			});
	};

	return (
		<div className='flex justify-center  '>
			<div className='bg-white md:p-8 p-2  rounded-lg shadow-lg md:max-w-[90%] max-w-full'>
				<div className='flex flex-col items-center pb-6'>
					<h3 className='text-lg font-semibold'>تعديل الملف الشخصي</h3>
				</div>
				<div className='flex flex-col items-center pb-6'>
					<Avatar
						src={imagePreview}
						alt='Profile Preview'
						sx={{ width: 90, height: 90, mb: 2 }}
					/>
					<Button
						variant='contained'
						color='primary'
						sx={{ mt: 2 }}
						onClick={() => fileInputRef.current.click()} // Open file dialog on button click
					>
						رفع صورة شخصية
					</Button>
					<TextField
						type='file'
						accept='image/*'
						onChange={handleFileChange}
						fullWidth
						margin='normal'
						style={{ display: "none" }} // Hide the file input
						inputRef={fileInputRef} // Reference to the input
					/>
				</div>
				<Box p={3}>
					<TextField
						sx={textFieldStyle}
						label='الاسم بالكامل '
						type='text'
						name='name'
						value={profileFormData.name}
						onChange={handleInputChange}
						fullWidth
						margin='normal'
					/>
					{errors?.name && (
						<Box className='flex justify-start items-center'>
							<Error sx={{ color: "red", fontSize: "18px" }} />
							<p className=' text-red-500'>{errors?.name}</p>
						</Box>
					)}

					<TextField
						sx={textFieldStyle}
						label='البريد الإلكتروني'
						type='email'
						name='email'
						value={profileFormData.email}
						onChange={handleInputChange}
						fullWidth
						margin='normal'
					/>
					{errors?.email && (
						<Box className='flex justify-start items-center'>
							<Error sx={{ color: "red", fontSize: "18px" }} />
							<p className=' text-red-500'>{errors?.email}</p>
						</Box>
					)}

					<TextField
						sx={textFieldStyle}
						label='رقم الجوال'
						type='text'
						name='phone'
						value={profileFormData.phone}
						onChange={handleInputChange}
						fullWidth
						margin='normal'
					/>
					{errors?.phone && (
						<Box className='flex justify-start items-center'>
							<Error sx={{ color: "red", fontSize: "18px" }} />
							<p className=' text-red-500'>{errors?.phone}</p>
						</Box>
					)}

					{errors?.mainError && (
						<Box className='flex justify-start items-center'>
							<Error sx={{ color: "red", fontSize: "18px" }} />
							<p className=' text-red-500'>{errors?.mainError}</p>
						</Box>
					)}

					<button
						onClick={handleEditProfile}
						disabled={
							reload ||
							!profileFormData?.name ||
							!profileFormData?.email ||
							!profileFormData?.phone
						}
						className='mt-4 w-full bg-color_01 hover:bg-color_03 text-white rounded-md h-12 tex-lg font-medium '>
						{reload ? <CircularProgress sx={{ color: "#fff" }} /> : "تعديل "}
					</button>
				</Box>
			</div>
		</div>
	);
};

export default EditUserProfile;
