import { createSlice } from "@reduxjs/toolkit";

import {
	CancelOrderThunk,
	FinishOrderThunk,
	GetOrdersThunk,
	ShowOrderByIdThunk,
} from "../Thunk/CreateOrderThunk";

let initState = {
	reload: true,
	orders: null,
	order: null,
	currentPage: null,
	lastPage: null,
};

const GetOrdersReducer = createSlice({
	name: "GetOrdersReducer",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(GetOrdersThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(GetOrdersThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.orders = action.payload.data;
				state.currentPage = action.payload.meta.current_page;
				state.lastPage = action.payload.meta.last_page;
			})
			.addCase(GetOrdersThunk.rejected, (state, action) => {
				state.reload = true;
			})

			.addCase(ShowOrderByIdThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(ShowOrderByIdThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.order = action.payload.data;
			})

			.addCase(ShowOrderByIdThunk.rejected, (state, action) => {
				state.reload = false;
			})
			.addCase(CancelOrderThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(CancelOrderThunk.fulfilled, (state, action) => {
				state.reload = false;
			})

			.addCase(CancelOrderThunk.rejected, (state, action) => {
				state.reload = false;
			})
			.addCase(FinishOrderThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(FinishOrderThunk.fulfilled, (state, action) => {
				state.reload = false;
			})

			.addCase(FinishOrderThunk.rejected, (state, action) => {
				state.reload = false;
			});
	},
});
export default GetOrdersReducer.reducer;
