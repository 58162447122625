import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { Store } from "./RTK/Store";

// import css styles file
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={Store}>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</Provider>
);
