import React, { useState } from "react";
import {
	Box,
	CircularProgress,
	IconButton,
	InputAdornment,
	TextField,
} from "@mui/material";
import { Error, Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ChangePasswordThunk } from "../../../RTK/Thunk/ChangePasswordThunk";

const textFieldStyle = {
	"& .MuiOutlinedInput-notchedOutline": {
		textAlign: "right",
	},
	"& .MuiInputLabel-root": {
		left: "auto",
		right: "29px",
		transformOrigin: "top right",
	},

	"& .MuiOutlinedInput-root": {
		"@media (max-width: 600px)": {
			height: "46px",
		},
	},
};

const ChangePassword = () => {
	const dispatch = useDispatch();
	const { reload } = useSelector((state) => state.ChangePasswordReducer);
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [passwordData, setPasswordData] = useState({
		old_password: "",
		new_password: "",
		new_password_confirmation: "",
	});

	const handleClickShowOldPassword = () => {
		setShowOldPassword(!showOldPassword);
	};
	const handleClickShowNewPassword = () => {
		setShowNewPassword(!showNewPassword);
	};
	const handleClickShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	const handleInputChange = (e) => {
		setPasswordData({
			...passwordData,
			[e.target.name]: e.target.value,
		});
	};

	const [errors, setErrors] = useState({
		mainError: "",

		old_password: "",
		new_password: "",
		new_password_confirmation: "",
	});

	const resetPasswordData = () => {
		setPasswordData({
			old_password: "",
			new_password: "",
			new_password_confirmation: "",
		});
	};
	const resetError = () => {
		setErrors({
			mainError: "",
			old_password: "",
			new_password: "",
			new_password_confirmation: "",
		});
	};

	const handleEditProfile = async () => {
		resetError();

		const formData = new FormData();

		formData.append("_method", "PUT");
		formData.append("old_password", passwordData.old_password);
		formData.append("new_password", passwordData.new_password);
		formData.append(
			"new_password_confirmation",
			passwordData.new_password_confirmation
		);

		dispatch(ChangePasswordThunk(formData))
			.unwrap()
			.then((data) => {
				if (data?.code === 200 && data?.type === "success") {
					resetPasswordData();
					toast.success(data.message, { theme: "light" });
				}
			})

			.catch((error) => {
				setErrors({
					...errors,
					mainError: error?.data?.message,
					old_password: error?.data?.old_password,
					new_password: error?.data?.new_password,
					new_password_confirmation: error?.data?.new_password_confirmation,
				});

				toast.error(error?.data?.old_password, { theme: "light" });
				toast.error(error?.data?.new_password, { theme: "light" });
				toast.error(error?.data?.new_password_confirmation, { theme: "light" });
			});
	};

	return (
		<div className='flex justify-center'>
			<div className='bg-white md:p-8 p-2 rounded-lg shadow-lg md:max-w-[70%] max-w-full'>
				<div className='flex flex-col items-center pb-6'>
					<h3 className='text-lg font-semibold'>تغيير كلمة المرور </h3>
				</div>

				<Box p={3}>
					<TextField
						sx={textFieldStyle}
						label=' كلمة المرور الحالية'
						type={showOldPassword ? "text" : "password"}
						name='old_password'
						value={passwordData.old_password}
						onChange={handleInputChange}
						fullWidth
						margin='normal'
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton onClick={handleClickShowOldPassword}>
										{showOldPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					{errors?.old_password && (
						<Box className='flex justify-start items-center'>
							<Error sx={{ color: "red", fontSize: "18px" }} />
							<p className=' text-red-500'>{errors?.old_password}</p>
						</Box>
					)}

					<TextField
						sx={textFieldStyle}
						label='كلمة المرور الجديدة'
						type={showNewPassword ? "text" : "password"}
						name='new_password'
						value={passwordData.new_password}
						onChange={handleInputChange}
						fullWidth
						margin='normal'
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton onClick={handleClickShowNewPassword}>
										{showNewPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					{errors?.new_password && (
						<Box className='flex justify-start items-center'>
							<Error sx={{ color: "red", fontSize: "18px" }} />
							<p className=' text-red-500'>{errors?.new_password}</p>
						</Box>
					)}
					<TextField
						sx={textFieldStyle}
						label='تأكيد كلمة المرور'
						type={showConfirmPassword ? "text" : "password"}
						name='new_password_confirmation'
						value={passwordData.new_password_confirmation}
						onChange={handleInputChange}
						fullWidth
						margin='normal'
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton onClick={handleClickShowConfirmPassword}>
										{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					{passwordData.new_password_confirmation &&
						passwordData.new_password &&
						passwordData.new_password_confirmation !==
							passwordData.new_password && (
							<Box className='flex justify-start items-center'>
								<Error sx={{ color: "red", fontSize: "18px" }} />
								<p className=' text-red-500'>كلمة المرور غير متطابقة!</p>
							</Box>
						)}

					<button
						onClick={handleEditProfile}
						disabled={
							reload ||
							!passwordData?.old_password ||
							!passwordData?.new_password ||
							!passwordData?.new_password_confirmation
						}
						className='mt-4 w-full bg-color_01 hover:bg-color_03 text-white rounded-md h-12 tex-lg font-medium '>
						{reload ? (
							<CircularProgress sx={{ color: "#fff" }} />
						) : (
							"حفظ التعديلات "
						)}
					</button>
				</Box>
			</div>
		</div>
	);
};

export default ChangePassword;
