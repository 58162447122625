import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const ElectronicServicesThunk = createAsyncThunk(
	"ElectronicServicesThunk",
	async (_, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;
		try {
			const res = await axios.get(
				"https://api.nueawin.com/api/public/electronic_services"
			);

			return res?.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
