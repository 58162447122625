import React, { useState } from "react";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RegisterThunk } from "../../RTK/Thunk/RegisterThunk";
import { Error } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

const textFieldStyle = {
	"& .MuiOutlinedInput-notchedOutline": {
		textAlign: "right",
	},
	"& .MuiInputLabel-root": {
		left: "auto",
		right: "29px",
		transformOrigin: "top right",
	},

	"& .MuiOutlinedInput-root": {
		"@media (max-width: 600px)": {
			height: "46px",
		},
	},
};
const RegisterTab = ({ setActiveTab }) => {
	const dispatch = useDispatch();
	const { reload } = useSelector((state) => state.RegisterReducer);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [registerFormData, setRegisterFormData] = useState({
		name: "",
		email: "",
		phone: "",
		password: "",
		password_confirmation: "",
	});
	const [errors, setErrors] = useState({
		mainError: "",
		name: "",
		email: "",
		phone: "",
		password: "",
		password_confirmation: "",
	});
	const resetError = () => {
		setErrors({
			mainError: "",
			name: "",
			email: "",
			phone: "",
			password: "",
			password_confirmation: "",
		});
	};
	const resetData = () => {
		setRegisterFormData({
			name: "",
			email: "",
			phone: "",
			password: "",
			password_confirmation: "",
		});
	};
	const handleInputChange = (e) => {
		setRegisterFormData({
			...registerFormData,
			[e.target.name]: e.target.value,
		});
	};
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const handleClickShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	const handleRegister = async () => {
		resetError();
		dispatch(RegisterThunk(registerFormData))
			.unwrap()
			.then((data) => {
				if (data?.code === 201 && data?.type === "success") {
					toast.success(data.message, { theme: "light" });

					resetData();
					setActiveTab(0);
				}
			})

			.catch((error) => {
				setErrors({
					...errors,
					mainError: error?.data?.message,
					name: error?.data?.name,
					email: error?.data?.email,
					phone: error?.data?.phone,
					password: error?.data?.password,
					password_confirmation: error?.data?.password_confirmation,
				});

				toast.error(error.message, { theme: "light" });
			});
	};

	return (
		<Box p={3}>
			<TextField
				sx={textFieldStyle}
				label='الاسم بالكامل '
				type='text'
				name='name'
				value={registerFormData.name}
				onChange={handleInputChange}
				fullWidth
				margin='normal'
			/>
			{errors?.name && (
				<Box className='flex justify-start items-center'>
					<Error sx={{ color: "red", fontSize: "18px" }} />
					<p className=' text-red-500'>{errors?.name}</p>
				</Box>
			)}

			<TextField
				sx={textFieldStyle}
				label='البريد الإلكتروني'
				type='email'
				name='email'
				value={registerFormData.email}
				onChange={handleInputChange}
				fullWidth
				margin='normal'
			/>
			{errors?.email && (
				<Box className='flex justify-start items-center'>
					<Error sx={{ color: "red", fontSize: "18px" }} />
					<p className=' text-red-500'>{errors?.email}</p>
				</Box>
			)}

			<TextField
				sx={textFieldStyle}
				label='رقم الجوال'
				type='text'
				name='phone'
				value={registerFormData.phone}
				onChange={handleInputChange}
				fullWidth
				margin='normal'
			/>
			{errors?.phone && (
				<Box className='flex justify-start items-center'>
					<Error sx={{ color: "red", fontSize: "18px" }} />
					<p className=' text-red-500'>{errors?.phone}</p>
				</Box>
			)}

			<TextField
				sx={{
					"& .MuiOutlinedInput-notchedOutline": {
						textAlign: "right",
					},
					"& .MuiInputLabel-root": {
						left: "auto",
						right: "29px",
						transformOrigin: "top right",
					},
				}}
				label='كلمة المرور'
				type={showPassword ? "text" : "password"}
				name='password'
				value={registerFormData.password}
				onChange={handleInputChange}
				fullWidth
				margin='normal'
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<IconButton onClick={handleClickShowPassword}>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			{errors?.password && (
				<Box className='flex justify-start items-center'>
					<Error sx={{ color: "red", fontSize: "18px" }} />
					<p className=' text-red-500'>{errors?.password}</p>
				</Box>
			)}
			<TextField
				sx={textFieldStyle}
				label='تأكيد كلمة المرور'
				type={showConfirmPassword ? "text" : "password"}
				name='password_confirmation'
				value={registerFormData.password_confirmation}
				onChange={handleInputChange}
				fullWidth
				margin='normal'
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							<IconButton onClick={handleClickShowConfirmPassword}>
								{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			{registerFormData.password_confirmation &&
				registerFormData.password &&
				registerFormData.password_confirmation !==
					registerFormData.password && (
					<Box className='flex justify-start items-center'>
						<Error sx={{ color: "red", fontSize: "18px" }} />
						<p className=' text-red-500'>كلمة المرور غير متطابقة!</p>
					</Box>
				)}

			{errors?.mainError && (
				<Box className='flex justify-start items-center'>
					<Error sx={{ color: "red", fontSize: "18px" }} />
					<p className=' text-red-500'>{errors?.mainError}</p>
				</Box>
			)}
			{errors?.password_confirmation && (
				<Box className='flex justify-start items-center'>
					<Error sx={{ color: "red", fontSize: "18px" }} />
					<p className=' text-red-500'>{errors?.password_confirmation}</p>
				</Box>
			)}
			<button
				onClick={handleRegister}
				disabled={
					reload ||
					!registerFormData?.name ||
					!registerFormData?.email ||
					!registerFormData?.phone ||
					!registerFormData?.password ||
					!registerFormData?.password_confirmation
				}
				className='mt-4 w-full bg-color_01 hover:bg-color_03 text-white rounded-md h-12 tex-lg font-medium '>
				{reload ? <CircularProgress sx={{ color: "#fff" }} /> : "إنشاء حساب"}
			</button>
		</Box>
	);
};

export default RegisterTab;
