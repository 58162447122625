import React from "react";
import { Link, Outlet } from "react-router-dom";
import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import VerticalMenu from "./VerticalMenu";
import { FaHome } from "react-icons/fa";

const UserDashboard = () => {
	return (
		<Box className=' px-1 pb-20 bg-gray-100'>
			<Box className='container mx-auto'>
				{/* Breadcrumbs */}
				<Box role='presentation' className='mb-12 pt-6'>
					<Breadcrumbs aria-label='breadcrumb'>
						<Link
							underline='hover'
							color='inherit'
							to='/'
							className='flex items-center gap-1'>
							<FaHome />
							الرئيسية
						</Link>
						<Typography
							color='text.primary'
							className='font-medium text-[#283078]'>
							لوحة التحكم
						</Typography>
					</Breadcrumbs>
				</Box>
				<Grid
					container
					sx={{
						"&.MuiGrid-root": {
							justifyContent: "space-between",
						},
					}}>
					{/* Vertical Menu */}
					<Grid item xs={12} md={1}>
						<VerticalMenu />
					</Grid>

					{/* Main Content Area */}
					<Grid item xs={12} md={10}>
						<Outlet />
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default UserDashboard;
